<div class="modal" (click)="close()">
  <div class="content" (click)="stopPropegation($event)">
    <h5
      (click)="close()"
      class="border-0 position-absolute top-0 end-0 font-600 font-24 me-2"
      role="button"
    >
      x
    </h5>
    <ng-content></ng-content>
  </div>
</div>
